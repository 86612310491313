import { useSelector } from 'react-redux'
import { State } from '../common/types'
import { useEffect, useState } from 'react'
import { enableRTLSupport } from '../features'

const useDirection = () => {
  const { locale } = useSelector((state: State) => state.user)
  const [isRTL, setIsRTL] = useState(false)
  useEffect(() => {
    const rtlLocales = ['ar_YE', 'ar_SA', 'he_IL']

    setIsRTL(rtlLocales.includes(locale) && enableRTLSupport)
  }, [locale, setIsRTL])
  return {
    isRTL
  }
}

export default useDirection

import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation, Trans } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import Parse from 'libphonenumber-js/max'
import Button from '@veneer/core/dist/esm/scripts/button'
import InlineNotification from '@veneer/core/dist/esm/scripts/inline_notification'
import { MainLayout, DefaultHeader, UITextBox } from '../../component'
import { State } from '../../common/types'
import { PAGE_USERNAME, ARKOSE_SELECTORS } from '../../constants'
import udlEvents from '../../common/udlEvents'

import * as S from './styles'

const OTPSignIn = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { retryDelay } = useSelector((state: State) => state.session)
  //TODO: Change this to the correct endpoint coming from the backend (otpVerifyCode)
  const { login } = useSelector((state: State) => state.loading.effects.user)
  const user = useSelector((state: State) => state.user)
  const [isLoading, setLoading] = useState(false)
  const username = Parse(user.username)?.isValid() ? Parse(user.username).formatInternational() : user.username
  const submitId = ARKOSE_SELECTORS.SIGN_IN.OTP
  const resendLinkId = ARKOSE_SELECTORS.SIGN_IN.RESEND_PASSCODE

  //TODO: This information will come from the backend. We need to check it to render the correct message.
  const hasMultipleAccounts = false

  const defaultValues = { passcode: '' }
  const {
    handleSubmit,
    formState: { errors },
    setError,
    control
  } = useForm({
    mode: 'onBlur',
    defaultValues
  })

  // TODO: Must change this method to match with the new endpoint coming from the backend (verify code)
  const submit = async () => {
    const options = { setError, setLoading }

    setLoading(true)

    await dispatch.user.otpLoginVerifyCode({
      options: { ...options }
    })
  }

  // TODO: Must change this method to match with the new endpoint coming from the backend (resend code)
  const handleResendPasscode = async () => {
    dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT195'))
    dispatch.session.update({ retryDelay: true })
  }

  const handleBackLink = () => {
    navigate(PAGE_USERNAME)
  }

  const handleMessageDescription = () =>
    hasMultipleAccounts ? (
      <Trans i18nkey="label.check_multiple_accounts">
        You are logging in with your <strong>local account</strong>. Check <strong>{{ EMAIL: username }}</strong>{' '}
        for a message from HP.
      </Trans>
    ) : (
      <Trans
        i18nKey="label.check_email_phone"
        components={{ bold: <strong /> }}
        values={{ EMAILORPHONE: `<bold>${username}</bold>` }}
      >
        Check {{ username }} for a message from HP.
      </Trans>
    )

  return (
    <>
      <MainLayout>
        <DefaultHeader title={t('label.sign_in')} />
        <form onSubmit={handleSubmit(submit)}>
          <S.MessageContainer>{handleMessageDescription()}</S.MessageContainer>
          <S.InputContainer>
            <UITextBox
              id="passcode"
              aria-label="passcode"
              type="tel"
              maxLength={6}
              filter={(value: string) => value.replace(/\D/g, '')}
              label={t('form.enterPasscode')}
              error={!!errors.passcode}
              helperText={errors.passcode && t(errors.passcode.message)}
              control={control}
              rules={{ required: 'form.enterPasscode' }}
              autoFocus
            />
          </S.InputContainer>
          <S.ButtonContainer>
            <Button
              id={submitId}
              aria-label="submit-button"
              name={submitId}
              type="submit"
              loading={isLoading || login}
              expanded
            >
              {t('button_capitalize.sign_in_2')}
            </Button>
          </S.ButtonContainer>
          <S.ButtonContainer>
            <Button
              tabIndex={0}
              id={resendLinkId}
              aria-label={resendLinkId}
              appearance="ghost"
              onClick={handleResendPasscode}
              disabled={retryDelay}
              expanded
            >
              {t('label.request_new_message')}
            </Button>
          </S.ButtonContainer>
          {retryDelay && (
            <S.ElementsContainer>
              <InlineNotification
                id="passcode-message-resent"
                aria-label="passcode-message-resent"
                hideIcon={false}
                closeButton={false}
                title={t('label.resend_passcode_message')}
                type="informative"
              />
            </S.ElementsContainer>
          )}
          <S.ElementsContainer>
            <Button id="back-link" appearance="ghost" onClick={handleBackLink} aria-label="back-link" expanded>
              {t('link.back_link')}
            </Button>
          </S.ElementsContainer>
        </form>
      </MainLayout>
    </>
  )
}

export default OTPSignIn

import ThemeProvider from '@veneer/theme/dist/esm/theme_provider'
import CssBaseline from '@veneer/core/dist/esm/scripts/css_baseline'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { State } from '../../common/types'
import { IMAGE_RESOLUTION, PAGES_WITH_BACKGROUND, PARAM_THEME_ID } from '../../constants'
import useWindowSize from '../../customHooks/useWindowSize'
import { enableHighContrast, enableRTLSupport } from '../../features'
import '@veneer/core/dist/css/veneer.css'
import * as S from './styles'
import { getIEClient } from '../../util'

const UIThemeProvider = ({ children }) => {
  const isIE = getIEClient()
  const { client } = useSelector((state: State) => state.session)
  const { locale } = useSelector((state: State) => state.user)
  const [direction, setDirection] = useState('ltr')
  const [width] = useWindowSize()
  const theme = useSelector((state: State) => state.session[PARAM_THEME_ID])
  const [brandingImage, setBrandingImage] = useState('')
  const [alignItens, setAlignItens] = useState('center')

  // eslint-disable-next-line react-hooks/exhaustive-deps
  let highContrastQuery = { matches: false } as any
  try {
    highContrastQuery = window.matchMedia('(forced-colors: active)')
  } catch (e) {
    console.error('highContrast not enabled')
  }
  const lightOrDarkTheme = theme === 'dark' || theme === 'omen' ? 'dark' : 'light'
  const [activeTheme, setActiveTheme] = useState(
    highContrastQuery.matches && enableHighContrast && !isIE ? 'contrast' : lightOrDarkTheme
  )
  const images = useMemo(() => [], [])
  const dispatch = useDispatch()
  const location = useLocation()

  useEffect(() => {
    const query = new URLSearchParams(location.search)
    if (query.has(PARAM_THEME_ID)) {
      const themeParam = query.get(PARAM_THEME_ID)
      dispatch.session.update({ [PARAM_THEME_ID]: themeParam })
    }
  }, [theme, dispatch, location])

  const updateBrandingImage = useCallback(() => {
    // Check if width size is above 768px or route isn't one that allows background
    if (width < 768 || !PAGES_WITH_BACKGROUND.includes(location.pathname)) {
      setAlignItens('center')
      return setBrandingImage('')
    }

    let size = ''
    if (width >= 1200) {
      size = IMAGE_RESOLUTION.X_LARGE
    } else if (width >= 992) {
      size = IMAGE_RESOLUTION.LARGE
    } else if (width >= 768) {
      size = IMAGE_RESOLUTION.MEDIUM
    }
    setBrandingImage(images[size])
    setAlignItens('flex-end')
  }, [width, images, location])

  useEffect(() => {
    if (client.brandingImages.length) {
      client.brandingImages.forEach((image) => {
        if (image.resolution === IMAGE_RESOLUTION.X_LARGE) {
          images[IMAGE_RESOLUTION.X_LARGE] = image.imageUri
        } else if (image.resolution === IMAGE_RESOLUTION.LARGE) {
          images[IMAGE_RESOLUTION.LARGE] = image.imageUri
        } else if (image.resolution === IMAGE_RESOLUTION.MEDIUM) {
          images[IMAGE_RESOLUTION.MEDIUM] = image.imageUri
        }
      })
      updateBrandingImage()
    }
  }, [client.brandingImages, images, width, brandingImage, updateBrandingImage])

  useEffect(() => {
    !highContrastQuery.matches && setActiveTheme(lightOrDarkTheme)
    if (enableHighContrast && !isIE) {
      highContrastQuery.addEventListener &&
        highContrastQuery.addEventListener('change', () => {
          setActiveTheme(highContrastQuery.matches ? 'contrast' : lightOrDarkTheme)
        })
    }
  }, [highContrastQuery.matches, isIE, lightOrDarkTheme, highContrastQuery])

  useEffect(() => {
    const rtlLocales = ['ar_YE', 'ar_SA', 'he_IL']

    setDirection(rtlLocales.includes(locale) ? 'rtl' : 'ltr')
  }, [locale])

  return (
    <S.UIThemeProvider backgroundUrl={brandingImage} data-testid={brandingImage}>
      <ThemeProvider mode={activeTheme}>
        <CssBaseline />
        <S.ThemeWrapper alignItens={alignItens}>
          {enableRTLSupport ? (
            <div dir={direction}>
              {children}
            </div>
          ) : (
            children
          )}
        </S.ThemeWrapper>
      </ThemeProvider>
    </S.UIThemeProvider>
  )
}

export default UIThemeProvider

import { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation, Trans } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import Parse from 'libphonenumber-js/max'
import Button from '@veneer/core/dist/esm/scripts/button'
import InlineNotification from '@veneer/core/dist/esm/scripts/inline_notification'
import { UITextBox, MainLayout, DefaultHeader } from '../../component'
import { setWebOTPApi } from '../../util'
import {
  ARKOSE_SELECTORS,
  LINK_SELECTORS,
  BFF_IDENTITY_PROVIDER,
  PAGE_EDIT_MOBILE,
  IDENTITY_PROVIDER
} from '../../constants'
import config from '../../config'
import { State } from '../../common/types'
import { editMobileForWechat } from '../../features'
import udlEvents from '../../common/udlEvents'
import useDirection from '../../customHooks/useDirection'

import * as S from './styles'

const PhoneVerification = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { user, session } = useSelector((state: State) => state)
  const { phoneVerificationSendCode, retryDelay } = session
  const { phoneVerificationFormVerifySMSCode, resendSMSCode } = useSelector(
    (state: State) => state.loading.effects.codeVerification
  )

  const PHONENUMBER = Parse(user.phoneNumber).formatInternational()

  const defaultValues = { code: '' }
  const [resendCode, setResendCode] = useState('')
  const {
    handleSubmit,
    formState: { errors },
    setError,
    control,
    setValue
  } = useForm({ mode: 'onBlur', defaultValues })
  const [loadingResend, setLoadingResend] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [isLoadingEmail, setLoadingEmail] = useState(false)
  const submitSelectorByPhone = ARKOSE_SELECTORS.PHONE_VERIFICATION.SUBMIT_CODE
  const submitSelectorByEmail = ARKOSE_SELECTORS.PHONE_VERIFICATION.SUBMIT_CODE_BY_EMAIL
  const resendSelector = ARKOSE_SELECTORS.PHONE_VERIFICATION.RESEND_CODE
  const editSelector = LINK_SELECTORS.EDIT_PHONE
  const { isRTL } = useDirection()
  const formattedNumber = isRTL ? `<bold dir="ltr">${PHONENUMBER}</bold>` : `<bold>${PHONENUMBER}</bold>`

  const handleVerifyCodeByEmail = async () => {
    dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT91'))
    setLoadingEmail(true)
    const options = { setLoadingEmail, setError }
    const arkoseParams = {
      options,
      submitSelector: submitSelectorByEmail,
      callback: dispatch.user.phoneVerificationFormVerifyEmail,
      onHide: () => setLoadingEmail(false),
      formErrors: errors
    }
    await dispatch.user.phoneVerificationFormVerifyEmail({
      options: { ...options, arkoseParams }
    })
  }

  const handleVerifyCode = useCallback(
    async ({ code }) => {
      setLoading(true)
      const options = { setLoading, setError, code }
      const arkoseParams = {
        options,
        submitSelector: submitSelectorByPhone,
        callback: dispatch.user.phoneVerificationFormVerifySMSCode,
        onHide: () => setLoading(false),
        formErrors: errors
      }
      await dispatch.user.phoneVerificationFormVerifySMSCode({
        options: { ...options, arkoseParams }
      })
    },
    [dispatch.user, errors, setError, submitSelectorByPhone]
  )

  const handleFormSubmit = (e) => {
    e.preventDefault()
    dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT90'))
    handleSubmit(handleVerifyCode)()
  }

  const resendHandler = async () => {
    setLoadingResend(true)
    const options = { setResendCode, setError }
    const arkoseParams = {
      callback: dispatch.codeVerification.resendSMSCode,
      submitSelector: resendSelector,
      onHide: () => setLoadingResend(false),
      options
    }
    await dispatch.codeVerification.resendSMSCode({
      options: { ...options, arkoseParams }
    })
  }

  useEffect(() => {
    if (phoneVerificationSendCode === 'failed') {
      dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT176'))
      setError('code', { type: 'serverError', message: 'form.err_send_email_code' })
      dispatch.codeVerification.update({ phoneVerificationSendCode: '' })
    }
  }, [dispatch, phoneVerificationSendCode, setError])

  useEffect(() => {
    setWebOTPApi({
      inputChange: (code) => {
        code && setValue('code', code, { shouldDirty: true })
      },
      formSubmit: handleSubmit(handleVerifyCode)
    })
  }, [handleSubmit, setValue, handleVerifyCode])

  return (
    <MainLayout>
      <DefaultHeader preventLineBreak={isRTL} isRTL={isRTL} title={t('label.verify_mobile_number')} />
      <S.BodyWrapper>
        <S.VerifyNumberMessage isRTL={isRTL}>
          <Trans
            i18nKey="label.check_mobile_message"
            components={{ bold: <strong dir="ltr" /> }}
            values={{ PHONENUMBER: formattedNumber }}
          />
        </S.VerifyNumberMessage>
        <>
          <form data-testid="form-submit-element" onSubmit={(e) => handleFormSubmit(e)}>
            <S.UITextContainer>
              <UITextBox
                id="code"
                aria-label="code"
                type="tel"
                error={!!errors.code}
                label={t('form.enterSixDigit')}
                helperText={errors.code && t(errors.code.message)}
                filter={(value) => value.replace(/\D/g, '')}
                autoFocus
                autoComplete="one-time-code"
                maxLength={6}
                control={control}
                rules={{
                  required: 'form.err_code_empty',
                  pattern: {
                    value: config.verificationCodeRegex,
                    message: 'form.err_invalid_email_code'
                  }
                }}
              />
            </S.UITextContainer>
            <S.VerifyButtonContainer>
              <Button
                id={submitSelectorByPhone}
                name={submitSelectorByPhone}
                type="submit"
                loading={isLoading}
                disabled={isLoadingEmail}
                appearance="secondary"
                expanded
              >
                {t('button_capitalize.verify')}
              </Button>
            </S.VerifyButtonContainer>
          </form>
          <div>
            {!!(user.phoneNumber && user.email) && (
              <Button
                id={submitSelectorByEmail}
                name={submitSelectorByEmail}
                appearance="ghost"
                loading={isLoadingEmail}
                disabled={isLoading || phoneVerificationFormVerifySMSCode || retryDelay}
                onClick={handleVerifyCodeByEmail}
                expanded
              >
                {t('button.verify_by_email')}
              </Button>
            )}
            <S.NotReceiveMessage>{t('label.if_not_received')}</S.NotReceiveMessage>
            {resendCode ? (
              <S.InLineContainer>
                <InlineNotification
                  id={1}
                  hideIcon={false}
                  title={
                    isRTL ? (
                      <Trans
                        components={{ wrap: <span dir="ltr" /> }}
                        i18nKey="label.email_resend_to"
                        values={{ EMAIL: `<wrap dir="ltr">${resendCode}</wrap>` }}
                      >
                        Verification code re-sent to <span>{{resendCode}}</span>
                      </Trans>
                    ) : (
                      t('label.email_resend_to', { EMAIL: resendCode })
                    )
                  }
                  type="positive"
                  closeButton={true}
                  onClose={() => setResendCode('')}
                />
              </S.InLineContainer>
            ) : (
              <S.ResendButtonContainer>
                <Button
                  aria-label={resendSelector}
                  id={resendSelector}
                  name={resendSelector}
                  onClick={resendHandler}
                  appearance="ghost"
                  expanded
                  loading={loadingResend || resendSMSCode}
                  disabled={retryDelay}
                >
                  {t('label.resend_message')}
                </Button>
              </S.ResendButtonContainer>
            )}
            {((!user.isExternalIdentity && !user.identityProvider) ||
              user.identityProvider === BFF_IDENTITY_PROVIDER.HPID ||
              (user.identityProvider &&
                user.identityProvider.toLowerCase() === IDENTITY_PROVIDER.WE_CHAT &&
                editMobileForWechat)) && (
              <S.ChangeMobileContainer>
                <Button
                  id={editSelector}
                  name={editSelector}
                  onClick={() => {
                    dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT52'))
                    navigate(PAGE_EDIT_MOBILE)
                  }}
                  appearance="ghost"
                  disabled={retryDelay}
                  expanded
                >
                  {t('link.change_mobile_number')}
                </Button>
              </S.ChangeMobileContainer>
            )}

            {retryDelay && (
              <S.InLineContainer>
                <InlineNotification
                  hideIcon={false}
                  closeButton={false}
                  title={t('label.code_in_moment_mobile')}
                  type="informative"
                />
              </S.InLineContainer>
            )}
          </div>
        </>
      </S.BodyWrapper>
    </MainLayout>
  )
}

export default PhoneVerification

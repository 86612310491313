import config from '../config.js'

export async function loadOptimizelyScript() {

  return new Promise((resolve, reject) => {
    const script = document.createElement('script')
    script.addEventListener('load', resolve)
    script.addEventListener('error', reject)
    script.src = config.optimizely.url
    script.async = true
    document.head.appendChild(script)
  })
}

export async function initializeOPClient(opEnabled) {
  try {
    if(opEnabled) {
      await loadOptimizelyScript()
    }
  } catch (error) {
    console.error('Error during Optimizely initialization: ', error)
  }
}

const optimizelyObj = {
  loadOptimizelyScript,
  initializeOPClient
}

export default optimizelyObj

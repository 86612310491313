import { useState } from 'react'
import Password from '@veneer/core/dist/esm/scripts/password'
import { Controller } from 'react-hook-form'
import PasswordWithReqs from './PasswordWithReqs'
import { controlledOnBlur, controlledOnChange } from '../utils'

interface UIPasswordProps {
  id: string
  control: any
  rules?: any
  required?: boolean
  isWithReqs?: boolean
  maxLength?: number
  className?: string
  error?: boolean | any
  weakPasswordError?: () => void
}

const UIPassword = (props: UIPasswordProps) => {
  const { id, control, rules, required = true, maxLength = 200, isWithReqs = false, weakPasswordError } = props
  const [isChanged, setIsChanged] = useState(false)

  const getPasswordComponent = (cProps) => {
    const handleOnChange = (value) => {
      if (!isChanged) setIsChanged(true)
      controlledOnChange({ cProps, value })
    }

    if (isWithReqs) {
      return (
        <PasswordWithReqs
          {...props}
          {...cProps}
          onChange={handleOnChange}
          onBlur={() => controlledOnBlur({ cProps, isChanged })}
          maxLength={maxLength}
          required={required}
          weakPasswordError={weakPasswordError}
        />
      )
    }
    return (
      <Password
        {...props}
        {...cProps}
        onChange={handleOnChange}
        onBlur={() => controlledOnBlur({ cProps, isChanged })}
        maxLength={maxLength}
        required={required}
      />
    )
  }

  return <Controller name={id} control={control} rules={rules} render={getPasswordComponent} />
}

export default UIPassword

import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import InlineNotification from '@veneer/core/dist/esm/scripts/inline_notification'
import Button from '@veneer/core/dist/esm/scripts/button'
import { State } from '../../common/types'
import udlEvents from '../../common/udlEvents'

import * as S from './styles'

interface OTPSignInProps {
  isPasswordLoading: boolean
  isOtpLoading: boolean
  handleRedirectOtp?: () => void
}

const OTPSignInComponent = ({ isPasswordLoading, isOtpLoading, handleRedirectOtp }: OTPSignInProps) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { retryDelay } = useSelector((state: State) => state.session)

  const handleOnClick = () => {
    dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT194'))
    handleRedirectOtp()
  }

  return (
    <>
      <S.otpButtonsContainer>
        <Button
          id="use-password"
          name="use-password"
          type="submit"
          loading={isPasswordLoading}
          disabled={isOtpLoading}
          expanded
        >
          {t('button_capitalize.use_password')}
        </Button>
      </S.otpButtonsContainer>
      <S.otpButtonsContainer>
        <Button
          id="use-otp-button"
          name="use-otp-button"
          loading={isOtpLoading}
          disabled={retryDelay || isPasswordLoading}
          onClick={handleOnClick}
          appearance="secondary"
          expanded
        >
          {t('button_capitalize.use_otp')}
        </Button>
      </S.otpButtonsContainer>
      {retryDelay && (
        <S.PasscodeResentContainer>
          <InlineNotification
            id="wait-for-request-passcode-desc"
            aria-label="wait-for-request-passcode-desc"
            hideIcon={false}
            closeButton={false}
            title={t('label.wait_for_request_passcode')}
            type="informative"
          />
        </S.PasscodeResentContainer>
      )}
    </>
  )
}

export default OTPSignInComponent

import config from '../config.js'
import launchdarkly from './launchdarkly.js'

/*
  We're using launchdarkly to look for feature flag "ui-frontend-rum-enabled", which is a
  boolean. If LD is not enabled, ui-frontend-rum-enabled will be looked for in the config object
  as per the way the LD module works.
 */
launchdarkly.getFeatureFlag('ui-frontend-rum-enabled').then(value => {
  if (!value) {
    return
  }

  /*
    Flag is on, let's enable RUM by loading the script. When it's loaded, we only
    need to initialize it with the config object.
   */
  const script = document.createElement('script')

  /*
    The below stuff runs when the script is loaded.
  */
  script.addEventListener('load', () => {
    try {
      const rumConfig =  {
        beaconUrl: config.rum.beaconUrl,
        rumAuth: config.rum.token,
        app: config.rum.applicationName,
        version: config.rum.version,
        environment: config.rum.environment
      }
      window.SplunkRum.init(rumConfig)
    } catch (err) {
      console.error('Error initializing RUM: ', err)
    }
  })

  script.src = config.rum.url
  script.crossOrigin = 'anonymous'
  script.integrity = config.rum.integrity
  script.async = true
  document.head.appendChild(script)
})

/*
  No need to export anything, enabling RUM is done as a side effect of importing
  the script. Using "naked" module can be done using syntax like this:
  `import './rum.js'
*/

import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import config from '../../config'

const DelayController = () => {
  const dispatch = useDispatch()
  const { retryDelay, timestampDelay } = useSelector(({ session }) => session)
  const [time, unit] = config.delay_timer
  const [endTime, setEndTime] = useState(moment(timestampDelay).add(time - 1, unit))

  useEffect(() => {
    if (retryDelay && !timestampDelay) {
      const start = moment()
      dispatch.session.update({ timestampDelay: start })
    }
  }, [dispatch.session, retryDelay, timestampDelay])

  useEffect(() => {
    if (timestampDelay) {
      setEndTime(moment(timestampDelay).add(time - 1, unit))
    }
  }, [timestampDelay, time, unit])

  useEffect(() => {
    if (retryDelay) {
      const remainingTime = endTime.diff(moment())
      if (remainingTime && endTime) {
        setTimeout(delayExpired, remainingTime)
      }
    }
    // eslint-disable-next-line
  }, [endTime])

  const delayExpired = () => {
    dispatch.session.update({ retryDelay: false, timestampDelay: 0 })
  }

  return null
}

export default DelayController
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation, Trans } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import IconClock from '@veneer/core/dist/esm/scripts/icons/icon_clock'
import Button from '@veneer/core/dist/esm/scripts/button'
import { State } from '../../common/types'
import { UIPassword, UITextBox, ConfirmPassword, UILink } from '..'
import { ARKOSE_SELECTORS, PAGE_LEARN_MORE, PAGE_LEAKED_CREDENTIALS_CHANGE_PASSWORD } from '../../constants'
import { getPasswordRequirements, isValidMobileNumber } from '../../util'
import udlEvents from '../../common/udlEvents'

import * as S from './styles'

const ChangePasswordForm = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()
  const { resetPassword, recoverPassword } = useSelector((state: State) => state.loading.effects.user)
  const { username } = useSelector((state: State) => state.user)
  const { retryDelay } = useSelector((state: State) => state.session)
  const [isLoading, setLoading] = useState(false)
  const [isResendLoading, setResendLoading] = useState(false)
  const isMobileNumber = isValidMobileNumber(username)
  const currentPage = location.pathname
  const confirmButtonId = ARKOSE_SELECTORS.CHANGE_PASSWORD_FORM.CONFIRM_BUTTON
  const resendLinkId = ARKOSE_SELECTORS.CHANGE_PASSWORD_FORM.RESEND_CODE_LINK

  const defaultValues = {
    code: '',
    password: '',
    confirmPassword: ''
  }

  const {
    handleSubmit,
    formState: { errors, dirtyFields },
    setError,
    getValues,
    control,
    clearErrors
  } = useForm({
    mode: 'onBlur',
    defaultValues
  })

  const sendUdlEvents = (leakedcredsEvent: string, forgotPasswordEvent: string) => {
    const eventID =
      currentPage === PAGE_LEAKED_CREDENTIALS_CHANGE_PASSWORD ? leakedcredsEvent : forgotPasswordEvent
    dispatch.udl.trackEvent(udlEvents.getEventByID(eventID))
  }

  const setWeakPasswordError = () => (
    <Trans i18nKey="label.update_app_for">
      For security reasons this password cannot be used.
      <UILink
        onClick={() => {
          dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT191'))
          navigate(PAGE_LEARN_MORE)
        }}
        ariaLabel="learn-more-link"
        id="learn-more-link"
        error
      >
        Learn more
      </UILink>
    </Trans>
  )

  const handlePasswordChange = async (data) => {
    setLoading(true)
    const options = {
      data,
      setError,
      setLoading,
      isMobileNumber,
      username,
      currentPage,
      setWeakPasswordError
    }

    const arkoseParams = {
      options,
      callback: dispatch.user.resetPassword,
      submitSelector: confirmButtonId,
      onHide: () => setLoading(false),
      formErrors: errors
    }

    await dispatch.user.resetPassword({ options: { ...options, arkoseParams } })
  }

  const handleFormSubmit = (e) => {
    e.preventDefault()
    sendUdlEvents('EVENT186', 'EVENT111')
    handleSubmit(handlePasswordChange)()
  }

  const passwordFieldOnBlur = () => {
    if (getValues('password') === getValues('confirmPassword')) {
      clearErrors('confirmPassword')
    } else {
      if (dirtyFields.confirmPassword) {
        sendUdlEvents('EVENT183', 'EVENT110')
        setError('confirmPassword', { type: 'invalidCode', message: 'form.err_confirm_password_not_match' })
      }
    }
  }

  const handleResendCode = () => {
    setResendLoading(true)
    const options = { isMobileNumber, setResendLoading, username }
    const arkoseParams = {
      options,
      callback: dispatch.user.resendCode,
      submitSelector: resendLinkId,
      onHide: () => setResendLoading(false),
      formErrors: errors
    }

    sendUdlEvents('EVENT185', 'EVENT08')
    dispatch.user.resendCode({ options: { ...options, arkoseParams } })
  }

  useEffect(() => {
    if (errors.code && errors.code.type === 'required') {
      sendUdlEvents('EVENT187', 'EVENT112')
    }

    if (errors.confirmPassword && errors.confirmPassword.type === 'required') {
      sendUdlEvents('EVENT188', 'EVENT109')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch.udl, errors.code, errors.confirmPassword])

  const validateConfirmPassword = (value) => {
    if (!value.trim()) return 'form.err_confirm_password_empty'
    if (value && getValues('password') !== value) {
      sendUdlEvents('EVENT183', 'EVENT110')
      return 'form.err_confirm_password_not_match'
    }
    if (!getPasswordRequirements(value).isValidPassword) {
      sendUdlEvents('EVENT184', 'EVENT113')
      return 'form.password_check_requirements'
    }
  }

  return (
    <>
      <form onSubmit={(e) => handleFormSubmit(e)}>
        <S.FormFieldsWrapper>
          <S.FieldWrapper>
            <UITextBox
              aria-label="code"
              id="code"
              type="tel"
              maxLength={6}
              filter={(value) => value.replace(/\D/g, '')}
              label={t('form.enterSixDigit')}
              error={!!errors.code}
              helperText={errors.code && t(errors.code.message)}
              autoFocus
              control={control}
              rules={{ required: 'form.err_code_empty' }}
            />
          </S.FieldWrapper>
          <S.FieldWrapper>
            <UIPassword
              aria-label="password"
              id="password"
              error={errors.password}
              label={t('label.enter_new_pass')}
              control={control}
              weakPasswordError={setWeakPasswordError}
              rules={{ required: 'form.err_password_create_empty' }}
              isWithReqs
              onBlurFunction={passwordFieldOnBlur}
            />
          </S.FieldWrapper>
          <S.FieldWrapper>
            <ConfirmPassword
              errors={errors}
              control={control}
              label={t('label.placeholder_confirm_new_password')}
              rules={{
                required: 'form.err_confirm_password_empty',
                validate: validateConfirmPassword
              }}
            />
          </S.FieldWrapper>
        </S.FormFieldsWrapper>
        <S.ButtonWrapper>
          <Button
            aria-label="submit"
            type="submit"
            id={confirmButtonId}
            name="confirm"
            loading={isLoading || resetPassword}
            expanded
          >
            {t('button_capitalize.confirm')}
          </Button>
        </S.ButtonWrapper>
      </form>
      <S.ResendWrapper>
        <Button
          tabIndex={0}
          id={resendLinkId}
          aria-label={resendLinkId}
          appearance="ghost"
          onClick={handleResendCode}
          loading={isResendLoading || recoverPassword}
          leadingIcon={retryDelay && <IconClock aria-label="clock-icon" />}
          expanded
          disabled={retryDelay}
        >
          {retryDelay ? t('label.wait_to_request') : t('label.request_new_code_2')}
        </Button>
      </S.ResendWrapper>
    </>
  )
}

export default ChangePasswordForm

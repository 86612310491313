import { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import Button from '@veneer/core/dist/esm/scripts/button'
import Checkbox from '@veneer/core/dist/esm/scripts/checkbox'
import { State } from '../../common/types'
import udlEvents from '../../common/udlEvents'
import {
  CountrySelect,
  DefaultHeader,
  FederatedSignupMessage,
  MainLayout,
  TnCSignUp,
  UILink,
  UIPassword,
  UITextBox,
  UIUsername,
  UpdateAppNotification,
  UIMobileNumber
} from '../../component'
import config from '../../config'
import {
  ARKOSE_KEYS,
  ARKOSE_SELECTORS,
  IDENTITY_PROVIDER,
  PARAM_ALLOW_RETURN,
  PARAM_EMAIL,
  PARAM_FIRSTNAME,
  PARAM_LASTNAME,
  SIMPLIFIED_CHINESE_LOCALE,
  USERNAME_TYPES,
  VALIDATION_TYPES,
  PAGE_PASSWORD,
  PAGE_LEARN_MORE
} from '../../constants'
import {
  getPasswordRequirements,
  isLastNameBeforeFirst,
  isPhoneString,
  isValidEmail,
  isWebAuthBroker,
  sleep,
  validateCountry,
  validateMobileNumber,
  getIEClient,
  filterUsername,
  handleCheckboxOnKeyDown
} from '../../util'
import { conditionHideConsentCheckbox, displayUpdateAppNotification } from '../../features'
import useSendEvent from '../../customHooks/useSendEvent'
import useDirection from '../../customHooks/useDirection'

import * as S from './styles'

interface LocationState {
  isExternalIdentity: boolean
  provider: string
}

const refs = {}

const SignUp = () => {
  // Common hook declaration and variables
  const location = useLocation()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const submitSelector = ARKOSE_SELECTORS.SIGN_UP.SUBMIT
  const { isExternalIdentity = false, provider = '' } = (location.state as LocationState) || {}
  const isOneHp = provider && provider.toLowerCase() === IDENTITY_PROVIDER.ONE_HP

  // Redux state variables
  const { user, session } = useSelector((state: State) => state)
  const { client, isTNCLoaded, smsSupportedCountryNumbers, tncs, showPhoneField, signUpEventSent } = session
  const { submitSplitSignUp } = useSelector((state: State) => state.loading.effects.user)
  const allowSwitch =
    client.emailValidation === VALIDATION_TYPES.OPTIONAL_EMAIL &&
    client.phoneValidation === VALIDATION_TYPES.OPTIONAL_EMAIL
  const allowReturn = user[PARAM_ALLOW_RETURN]
  // Returns true if the client does not provide the marketingConsent attribute, and returns false if the marketingConsent is an empty array.
  const hasMarketingConsents = client.marketingConsents === undefined ? true : client.marketingConsents.length > 0

  // Hook form definition
  const getMarketProperty = hasMarketingConsents ? user.country === 'US' || user.market || false : undefined

  // Determine if the renderMarketConsent should be rendered based on user type and `conditionHideConsentCheckbox` flag
  const renderMarketConsent =
    user.usernameType === USERNAME_TYPES.USERNAME_EMAIL && (!conditionHideConsentCheckbox || hasMarketingConsents)
  const userHasEmail = user.usernameType === USERNAME_TYPES.USERNAME_EMAIL

  const defaultValues = {
    firstName: filterUsername(user[PARAM_FIRSTNAME]) as string,
    lastName: filterUsername(user[PARAM_LASTNAME]) as string,
    email: user[PARAM_EMAIL] || '',
    phoneNumber: isPhoneString(user.username) ? user.username : '',
    password: '',
    ...(renderMarketConsent && {
      market: getMarketProperty
    }),
    countryResidence: validateCountry(user.country) ? user.country : ''
  }

  const {
    handleSubmit,
    formState: { errors, isDirty },
    setError,
    getValues,
    control,
    setValue
  } = useForm({ mode: 'onBlur', defaultValues, shouldUnregister: true })

  // useState hooks
  const [isLoading, setLoading] = useState(false)
  const [isSimplifiedChinese, setIsSimplifiedChinese] = useState(SIMPLIFIED_CHINESE_LOCALE.includes(user.locale))
  const [textVisibility, setTextVisibility] = useState('auto')
  const [hasScrollbar, setHasScrollbar] = useState(false)
  const [hideTextDelay, setHideTextDelay] = useState(100)
  const scrollRef = useRef(null)

  useEffect(() => {
    const focusOnField = () => {
      const formKeys = ['firstName', 'lastName', 'email']
      if (isLastNameBeforeFirst(user.locale)) {
        const aux = formKeys[1]
        formKeys[1] = formKeys[0]
        formKeys[0] = aux
      }
      const fieldToFocus = formKeys.find((key: any) => !getValues(key))
      if (fieldToFocus && refs[fieldToFocus]) refs[fieldToFocus].focus()
    }
    focusOnField()
  }, [getValues, user.locale])

  useEffect(() => {
    setIsSimplifiedChinese(SIMPLIFIED_CHINESE_LOCALE.includes(user.locale))
  }, [user.locale])

  useEffect(() => {
    if (!smsSupportedCountryNumbers.length) dispatch.session.fetchSmsSupportedCountryNumbers()
  }, [smsSupportedCountryNumbers, dispatch.session, user.country])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const el = scrollRef.current
    setHasScrollbar(el?.scrollHeight > el?.clientHeight)
  })

  // Conditional render handlers
  const renderNames = () => {
    const FirstName = (
      <S.NameInput>
        <UITextBox
          id="firstName"
          aria-label="firstName"
          autoComplete="given-name"
          key="firstName"
          label={t('label.placeholder_first_name')}
          error={!!errors.firstName}
          helperText={errors.firstName && t(errors.firstName.message)}
          required
          filter={filterUsername}
          refs={refs}
          control={control}
          rules={{
            required: 'form.err_first_name_empty',
            validate: (value) => !!value.trim() || 'form.err_first_name_empty'
          }}
        />
      </S.NameInput>
    )
    const LastName = (
      <S.NameInput>
        <UITextBox
          id="lastName"
          aria-label="lastName"
          autoComplete="family-name"
          key="lastName"
          label={t('label.placeholder_last_name')}
          error={!!errors.lastName}
          helperText={errors.lastName && t(errors.lastName.message)}
          required
          filter={filterUsername}
          refs={refs}
          control={control}
          rules={{
            required: 'form.err_last_name_empty',
            validate: (value) => !!value.trim() || 'form.err_last_name_empty'
          }}
        />
      </S.NameInput>
    )

    const names = [FirstName, LastName]
    if (isLastNameBeforeFirst(user.locale)) names.reverse()
    return names
  }

  const renderUsernameType = () => {
    const fields = []

    const email = {
      id: 'email',
      'aria-label': 'email',
      autoComplete: 'email',
      error: !!errors.email,
      label: t('label.placeholder_email'),
      helperTextVisibility: textVisibility,
      onBlur: async () => {
        setTextVisibility('visible')
        await sleep(hideTextDelay)
        setTextVisibility('auto')
      },
      helperText: errors.email
        ? typeof errors.email.message === 'string'
          ? t(errors.email.message)
          : setConflictError('username')
        : t('label.sign_up_email_subtitle'),
      disabled: isExternalIdentity && isValidEmail(user[PARAM_EMAIL]),
      refs,
      required: true,
      control,
      rules: {
        required: 'form.err_email_empty',
        validate: (value) => {
          const checkResult = isValidEmail(value, !isOneHp)
          if (!value.trim()) return 'form.err_email_empty'
          if (checkResult === true) return
          if (!checkResult.result && checkResult.isEmail && checkResult.isHpEmail) {
            return 'form.err_email_hp_invalid'
          }
          if (!checkResult.result && !checkResult.isEmail) {
            return 'form.err_email_invalid'
          }
        }
      }
    }

    const mobile = {
      id: 'phoneNumber',
      'aria-label': 'phoneNumber',
      error:
        errors.phoneNumber &&
        (typeof errors.phoneNumber.message === 'string'
          ? t(errors.phoneNumber.message)
          : setConflictError('mobile')),
      displayDisclaimer: client && client.phoneValidation !== VALIDATION_TYPES.NOT_REQUIRED,
      control,
      rules: {
        required: allowSwitch ? 'form.err_mobile_empty' : false,
        validate: (value) => validateMobileNumber(value)
      },
      required: allowSwitch
    }

    allowSwitch
      ? fields.push(
          <UIUsername
            key={0}
            cProps={[email, mobile]}
            allowSwitch
            disableSwitch={isLoading || submitSplitSignUp}
            isSmallMargin
            setHideTextDelay={setHideTextDelay}
            marginBottom={20}
          />
        )
      : fields.push(<UITextBox key={0} {...email} />)
    showPhoneField &&
      fields.push(
        <UIMobileNumber key={1} {...mobile} displayDisclaimer={false} label={t('label.mobile_optional')} />
      )
    return fields
  }

  const renderCheckbox = () => (
    <S.CheckBoxConsentWorkaround>
      <Checkbox
        aria-label="marketConsent"
        id="market"
        label={t('label.marketing_consent')}
        onClick={handleCheckboxClick}
        checked={getValues().market}
        disabled={isLoading || submitSplitSignUp}
        onKeyDown={handleCheckboxOnKeyDown}
      />
    </S.CheckBoxConsentWorkaround>
  )

  const setConflictError = (context) => (
    <Trans i18nKey="link.already_have_account">
      Already have an HP account?
      <UILink
        onClick={() => {
          dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT81'))
          navigate(PAGE_PASSWORD)
        }}
        id={context === 'username' ? 'already-exists' : 'already-exists-mobile'}
        ariaLabel="already-have-account"
        error
      >
        Sign in
      </UILink>
    </Trans>
  )

  const setWeakPasswordError = () => (
    <Trans i18nKey="label.update_app_for">
      For security reasons this password cannot be used.
      <UILink
        onClick={() => {
          dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT180'))
          navigate(PAGE_LEARN_MORE)
        }}
        ariaLabel="learn-more-link"
        id="learn-more-link"
        error
      >
        Learn more
      </UILink>
    </Trans>
  )

  const optionalEmailUDLEvents = () => {
    if (user.defaultUsernameType === USERNAME_TYPES.USERNAME_EMAIL) {
      if (user.usernameType === USERNAME_TYPES.USERNAME_EMAIL) {
        dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT42'))
      } else {
        dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT43'))
      }
    } else {
      if (user.usernameType === USERNAME_TYPES.USERNAME_MOBILE) {
        dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT44'))
      } else {
        dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT45'))
      }
    }
  }

  const onSubmit = async (data) => {
    const updatedData = {
      ...data
    }

    if (renderMarketConsent) {
      updatedData.market = getValues().market
    }

    if (updatedData.hasOwnProperty('email')) {
      updatedData.email = updatedData.email.trim()
    }

    setLoading(true)

    const options = {
      data: updatedData,
      isExternalIdentity,
      provider,
      setConflictError,
      setWeakPasswordError,
      setError,
      setLoading,
      defaultValues
    }

    const arkoseParams = {
      options,
      submitSelector,
      callback: dispatch.user.splitSignUpFormSubmit,
      onHide: () => setLoading(false),
      formErrors: errors,
      arkoseKey: ARKOSE_KEYS.SIGN_UP
    }

    if (allowSwitch) {
      optionalEmailUDLEvents()
    }

    await dispatch.user.splitSignUpFormSubmit({ options: { ...options, arkoseParams } })
  }

  const triggerUdlButtonEvent = () => {
    dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT46'))
  }

  const handleCheckboxClick = (e) => {
    // WORKAROUND for HP SMART / IE not handling the checkbox properly
    setValue('market', e.target.checked)
    e.target.checked
      ? dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT82'))
      : dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT83'))
  }

  const _isWebAuthBroker = isWebAuthBroker()
  const _isIe = getIEClient()

  const identityProvider =
    config.identityProviders?.find(({ name }) => name === user.identityProvider) || undefined
  const hasMultipleTncs = tncs.length >= 2
  const hasMultipleTncsForFederated = hasMultipleTncs && identityProvider
  const formHasErrors = Object.keys(errors).length > 0
  const { isRTL } = useDirection()

  useSendEvent(isDirty, signUpEventSent)

  return (
    <MainLayout isFooterOn={false} disableScrollbar>
      <DefaultHeader
        preventLineBreak={user.locale === 'ru_RU'}
        title={t('label.create_account')}
        hasSignInLink={!identityProvider}
      />
      {_isWebAuthBroker && displayUpdateAppNotification && <UpdateAppNotification />}
      <S.SignUpForm
        isFederated={identityProvider}
        hasMultipleTncsForFederated={hasMultipleTncsForFederated}
        onSubmit={handleSubmit(onSubmit)}
        hasScrollbar={hasScrollbar}
        isIE={_isIe}
        formHasErrors={formHasErrors}
        hasMultipleTncs={hasMultipleTncs}
        showPhoneField={showPhoneField}
      >
        {identityProvider && <FederatedSignupMessage identityProvider={identityProvider} />}
        <S.FormFields
          isFederated={identityProvider}
          hasMultipleTncsForFederated={hasMultipleTncsForFederated}
          ref={scrollRef}
          hasScrollbar={hasScrollbar}
          isIE={_isIe}
          showPhoneField={showPhoneField}
          hasMultipleTncs={hasMultipleTncs}
        >
          <S.NamesContainer isRTL={isRTL}>{renderNames()}</S.NamesContainer>
          <S.UsernameTypeContainer>{renderUsernameType()}</S.UsernameTypeContainer>
          {isExternalIdentity && (
            <CountrySelect
              errors={errors}
              control={control}
              onChange={(value) => {
                if (!userHasEmail) setValue('market', value === 'US')
                dispatch.user.update({ country: value })
              }}
              key={user.locale}
            />
          )}
          {!isExternalIdentity && (
            <UIPassword
              aria-label="password"
              id="password"
              autoComplete="off"
              label={t('label.placeholder_password')}
              error={errors.password}
              control={control}
              weakPasswordError={setWeakPasswordError}
              rules={{
                required: 'form.err_password_create_empty',
                validate: (value) => {
                  if (!value.trim()) return 'form.err_password_create_empty'
                  if (!getPasswordRequirements(value).isValidPassword) {
                    return 'form.password_check_requirements'
                  }
                }
              }}
              isWithReqs
            />
          )}
          {!isExternalIdentity && showPhoneField && (
            <CountrySelect
              errors={errors}
              control={control}
              onChange={(value) => {
                if (!userHasEmail) setValue('market', value === 'US')
                dispatch.user.update({ country: value })
              }}
              key={user.locale}
            />
          )}
          {renderMarketConsent &&
            (showPhoneField ? <S.CheckBoxWrapper>{renderCheckbox()}</S.CheckBoxWrapper> : renderCheckbox())}
        </S.FormFields>
        <S.FormFooter isRTL={isRTL} showPhoneField={showPhoneField} hasScrollbar={hasScrollbar} isIE={_isIe}>
          <TnCSignUp />
          <Button
            type="submit"
            id={submitSelector}
            name={submitSelector}
            loading={isLoading || submitSplitSignUp}
            disabled={!isTNCLoaded}
            onClick={() => triggerUdlButtonEvent()}
            expanded
          >
            {isSimplifiedChinese ? t('button.accept_create_account') : t('button_capitalize.create')}
          </Button>
          {allowReturn && (
            <div>
              <UILink
                id="back-link"
                onClick={() => {
                  dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT41'))
                  dispatch.user.redirectToAPP()
                }}
              >
                {isSimplifiedChinese ? t('link.decline_create_account') : t('link.not_now')}
              </UILink>
            </div>
          )}
        </S.FormFooter>
      </S.SignUpForm>
    </MainLayout>
  )
}

export default SignUp

import styled from '@emotion/styled'

export const RememberWrapper = styled.div`
  margin-top: 20px;
  span {
    max-width: 100% !important; // fix veneer calculation .vn-checkbox__span--tex
  }
`

export const ForgetUsername = styled.div<{isRTL?: boolean}>`
  margin: ${props => props.isRTL ? '16px' : '20px'} 0;
  display: flex;
  justify-content: center;
`

export const LinkWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 18px;
  margin-bottom: 20px;
`
export const FooterWrapper = styled.div`
  width: 100%;
`
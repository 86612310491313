import styled from '@emotion/styled'

export const RadioButton = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
`

export const RecoveryButton = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
`

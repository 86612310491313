import atob from 'atob'
import pako from 'pako'
import cookies from 'js-cookie'
import Parse from 'libphonenumber-js/max'
import config from './config'
import {
  BFF_STATUS,
  PARAM_CSRF_TOKEN,
  PARAM_FLOW_TOKEN,
  PARAM_FLOW_ID,
  COOKIE_REMEMBER_USERNAME,
  COOKIE_ERROR_MESSAGE,
  PARAM_LOCALE,
  PARAM_TARGET,
  PARAM_ALLOW_RETURN,
  PARAM_APP_INSTANCE_ID,
  PARAM_CAPTCHA_BYPASS_KEY,
  IS_PRISTINE,
  PRODUCTION_ENV,
  BASE_URL,
  PARAM_AUTOREDIRECT,
  PAGES_WITHOUT_SESSION,
  PARAM_USERNAME_TYPE,
  USERNAME_TYPES,
  BFF_ENDPOINTS
} from './constants'
import { blockSignInNativeHp } from './features'

export const getEnvironment = (backendUrl = config.backendUrl) => {
  return (
    // eslint-disable-next-line array-callback-return
    ['dev', 'qa', 'stg'].find((env) => {
      if (backendUrl.match(env)) {
        return env
      }
    }) || PRODUCTION_ENV
  )
}

export const isMissingCookie = (data) => {
  // We dont need to check the errorDescription (Part of another user story)
  return (
    BFF_STATUS.NO_COOKIE_FOUND === data?.error &&
    COOKIE_ERROR_MESSAGE.some((cookieErrorMessage) => data?.errorDescription.includes(cookieErrorMessage))
  )
}

export const isSessionExpired = (data) => {
  return (
    BFF_STATUS.EXPIRED_SESSION === data?.error &&
    COOKIE_ERROR_MESSAGE.some((expiredSessionErrorMessage) =>
      data?.errorDescription.includes(expiredSessionErrorMessage)
    )
  )
}

export const checkLocalStorage = () => {
  try {
    window.localStorage.setItem('test', 'test')
    window.localStorage.removeItem('test')
  } catch {
    return false
  }
  return true
}

export const isValidEmail = (value, shouldBlockHpEmails) => {
  if (!value) return false
  const trimmedValue = value.trim()

  if (shouldBlockHpEmails) {
    return {
      result: config.emailRegexBlockingHp.test(trimmedValue),
      isEmail: config.emailRegex.test(trimmedValue),
      isHpEmail: !config.emailRegexBlockingHp.test(trimmedValue)
    }
  }
  return config.emailRegex.test(trimmedValue)
}

export const isValidMobileNumber = (value) => {
  const parsedNumber = Parse(value || '')
  return parsedNumber ? parsedNumber.isValid() : false
}

export const validateEmail = (value, checkHpEmail = false, previousEmail = '') => {
  const checkResult = isValidEmail(value, checkHpEmail)
  if (!value.trim()) return 'form.err_email_empty'
  if (typeof checkResult === 'boolean' && checkResult === true) return
  if (!checkResult.result && checkResult.isEmail && checkResult.isHpEmail) {
    return 'form.err_invalid_hp_email'
  }
  if (!checkResult.result && !checkResult.isEmail) {
    return 'form.err_email_invalid'
  }
  if (previousEmail && previousEmail === value) {
    return 'form.err_email_in_use'
  }
}

export const validateUsername = (value) => {
  if (isHpEmail(value) && blockSignInNativeHp) {
    return 'form.err_invalid_hp_email'
  }
}

export const validateCountry = (value) => {
  return /[A-Z]{2}/.test(value) && config.supportedCountries.includes(value)
}

export const isHpEmail = (value) => {
  if (!value) {
    return false
  }
  const trimmedValue = value.trim()
  const isEmail = config.emailRegex.test(trimmedValue)
  const isHpEmail = !config.emailRegexBlockingHp.test(trimmedValue)
  return isEmail && isHpEmail
}

export const validateMobileNumber = (value) => {
  const phoneNumber = Parse(value || '')
  if ((value && !phoneNumber) || (phoneNumber && !phoneNumber.isValid())) {
    return 'form.err_sms_number_invalid'
  }
}

export const validatePassword = (password, requirements) => {
  for (let i = 0; i < requirements.length; i++) {
    const r = requirements[i]
    if (r.matchPattern) {
      const exp = new RegExp(r.matchPattern)
      return exp.test(password)
    }
  }
  return true
}

export const getPasswordRequirements = (value) => {
  const requirements = (value) => ({
    hasMinLength: value.length >= 8,
    hasLowerCase: new RegExp('^(?=.*[a-z]).*', 'g').test(value),
    hasUpperCase: new RegExp('^(?=.*[A-Z]).*', 'g').test(value),
    hasNumber: new RegExp('(?=.*[0-9]).*', 'g').test(value),
    hasSpecialCharacter: new RegExp('(?=.*[\\W_])', 'g').test(value)
  })
  const { hasMinLength, hasLowerCase, hasUpperCase, hasNumber, hasSpecialCharacter } = requirements(value)
  const policyCriteria = [hasLowerCase, hasUpperCase, hasNumber, hasSpecialCharacter].filter(Boolean).length >= 3
  const isValidPassword = hasMinLength && policyCriteria
  return {
    hasMinLength,
    hasLowerCase,
    hasUpperCase,
    hasNumber,
    hasSpecialCharacter,
    policyCriteria,
    isValidPassword
  }
}

export const shouldBackToApp = (data = { target: '' }, user) => {
  return !!(user && user[IS_PRISTINE] && user[PARAM_ALLOW_RETURN] && user[PARAM_TARGET] === data.target)
}

/**
 * Parse the param that comes from URL query string.
 * Assume type of the value is String. If it is undefined or null, it will be reset to ''
 * If the value is invalid, return with default value from config
 * If the value is longer than 256, it will be truncated to 256, except PARAM_FLOW_TOKEN
 *
 * @param {String} key
 * @param {String} value
 * @returns parsed value
 */
export const parseParam = (key, value = '') => {
  // check Boolean type param value with default configuration
  const defaultValues = config.paramDefaultValues || {}
  if (key in defaultValues) {
    if (value === 'true') return true
    if (value === 'false') return false
    return defaultValues[key]
  }

  // handle other type of param, truncate the length if it is longer than 256
  if (typeof value === 'string' && value.length > 256 && key !== PARAM_FLOW_TOKEN && key !== PARAM_FLOW_ID) {
    return value.substring(0, 256)
  }

  return value
}

export const getFailedPasswordRequirements = (requirements) => {
  for (let i = 0; i < requirements.length; i++) {
    if (!requirements[i].requirementSatisfied) {
      return requirements[i].type
    }
  }
}

const isHTML = (content) => /\s?<!doctype html>|(<html\b[^>]*>|<body\b[^>]*>|<x-[^>]+>)+/i.test(content)

const _getDeflatedTnc = (binary) => {
  const charData = binary.split('').map((char) => char.charCodeAt(0))
  const deflatedContent = new Uint8Array(charData)
  return deflatedContent
}

const _getDecodedTNC = (content) => {
  const SCRIPT_REGEX = /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi
  try {
    const bin = atob(content)
    let decodedContent = pako.inflate(_getDeflatedTnc(bin), { to: 'string' })
    if (SCRIPT_REGEX.test(decodedContent)) {
      decodedContent = decodedContent.replace(SCRIPT_REGEX, '')
    }
    if (!isHTML(decodedContent)) {
      decodedContent = decodedContent.replace(/\n/g, '<br/>').replace(/\r/g, '')
    }
    return decodedContent
  } catch (e) {
    return content
  }
}

export const parseTNC = (tncs = []) => {
  const getLink = (tnc) => {
    let startIndex = null
    let endIndex = null
    let count = 0
    if (tnc.displayTitle) {
      for (let i = 0; count < 2 && i < tnc.displayTitle.length; i++) {
        if (tnc.displayTitle[i] === '#') {
          count === 0 ? (startIndex = i) : (endIndex = i)
          count++
        }
      }
    }
    if (count < 2) return null
    return {
      startIndex,
      endIndex,
      text: tnc.displayTitle ? tnc.displayTitle.substring(startIndex + 1, endIndex) : ''
    }
  }
  const parsedTncs = tncs.map((tnc) => {
    return {
      displayTitle: tnc.displayTitle,
      tncId: tnc.tncId,
      content: _getDecodedTNC(tnc.content),
      locale: tnc.locale,
      country: tnc.country,
      link: getLink(tnc)
    }
  })
  return parsedTncs
}

export const getProviderName = (idp) => {
  const provider = (idp && config.identityProviders.find((item) => item.identityProvider === idp)) || {}
  return provider.name || ''
}

export const setCSRFToken = (token) => cookies.set(PARAM_CSRF_TOKEN, token, { sameSite: 'lax', path: '/' })

export const setAutoRedirectToken = (value) => cookies.set(PARAM_AUTOREDIRECT, value)
export const getAutoRedirectToken = () => cookies.get(PARAM_AUTOREDIRECT) || true

export const setUsernameType = (value) => cookies.set(PARAM_USERNAME_TYPE, value)
export const getUsernameType = () => cookies.get(PARAM_USERNAME_TYPE) || USERNAME_TYPES.USERNAME_EMAIL

export const setLocale = (locale) => {
  if (/\w{2}_\w{2}/.test(locale)) {
    window.localStorage.setItem(PARAM_LOCALE, locale)
  }
}

export const setCaptchaBypassKey = (arkoseBypassKey) => {
  if (getEnvironment() !== PRODUCTION_ENV && checkLocalStorage()) {
    window.sessionStorage.setItem(PARAM_CAPTCHA_BYPASS_KEY, arkoseBypassKey)
  }
}

export const parseLocaleString = (locale) => {
  const p = locale.replace('-', '_').split('_')
  if (p.length === 1) {
    locale = config.twoLettersLocaleConfig[p[0]] || config.defaultLocale
  } else if (p.length === 2) {
    p[0] = p[0].toLowerCase()
    p[1] = p[1].toUpperCase()
    locale = p.join('_')
  }
  return locale
}

export const setAppInstanceID = (appInstanceID) => {
  if (appInstanceID) {
    window.sessionStorage.setItem(PARAM_APP_INSTANCE_ID, appInstanceID)
  }
}

export const getArkoseLang = (locale) => {
  let retval = 'en'
  locale = locale.replace('_', '-')
  if (config.arkoseSupportedLanguages.indexOf(locale) !== -1) {
    retval = locale
  } else if (config.arkoseSupportedLanguages.indexOf(locale.toLowerCase()) !== -1) {
    retval = locale.toLowerCase()
  } else {
    const parts = locale.split('-')
    if (config.arkoseSupportedLanguages.indexOf(parts[0]) !== -1) {
      retval = parts[0]
    }
  }
  return retval
}

/**
 * save the username in cookie for 365 days
 * @param {String} username
 */
export const setRememberedUsername = (username = '') => {
  if (username) {
    cookies.set(COOKIE_REMEMBER_USERNAME, username, {
      sameSite: 'Strict',
      expires: 365
    })
  }
}

export const getRememberedUsername = () => cookies.get(COOKIE_REMEMBER_USERNAME) || ''

export const removeRememberedUsername = () => cookies.remove(COOKIE_REMEMBER_USERNAME)

export const isLastNameBeforeFirst = (locale = '') => {
  return config.lastNameBeforeFirst.includes(locale.toLowerCase())
}

export const getRecaptchaLanguageByLocale = (locale) => {
  return locale === 'es_LX' ? 'es-419' : locale
}

export const isPhoneString = (text) => !!text.match(/^\+?[0-9]*$/)

export const setWebOTPApi = (params) => {
  const { inputChange, formSubmit } = params
  if ('OTPCredential' in window) {
    const input = document.querySelector('input[autocomplete="one-time-code"]')
    if (!input || !inputChange || !formSubmit) return
    // eslint-disable-next-line no-undef
    const ac = new AbortController()
    const form = input.closest('form')
    if (form) {
      form.addEventListener('submit', (e) => {
        ac.abort()
      })
    }
    navigator.credentials
      .get({
        otp: { transport: ['sms'] },
        signal: ac.signal
      })
      .then((otp) => {
        inputChange(otp.code)
        if (form) formSubmit()
      })
      .catch((err) => {
        console.warn('OTP Promise error: ', err)
      })
  }
}

export const useQuery = ({ location }) => {
  return new URLSearchParams(location.search)
}

export const sleep = (delay) => new Promise((resolve) => setTimeout(resolve, delay))

export const nextUrlRetryBuilder = (url) => {
  if (!url || url === BASE_URL) return url
  const ERROR = 'user_timeout'
  const ERROR_DESCRIPTION = 'redirect taking too long to complete, user decided to give-up'
  const splitUrl = url.split('?')
  const getPureUrl = splitUrl[0]
  const newUrl = new URL(url)
  const params = new URLSearchParams(newUrl.search)
  params.delete('code')
  if (!params.has('error')) {
    params.append('error', ERROR)
  }
  if (!params.has('error_description')) {
    params.append('error_description', ERROR_DESCRIPTION)
  }
  return `${getPureUrl}?${params.toString()}`
}

export const getIEClient = () => {
  const ua = navigator.userAgent
  return ua.includes('MSIE') || ua.includes('Trident')
}

export const getSelectI18n = (t) => ({
  searchPlaceholder: t('label.search'),
  noResults: t('label.noResults'),
  showingResult: t('label.showingResult'),
  showingResults: t('label.showingResults'),
  clear: t('label.clear'),
  open: t('label.open'),
  selected: t('label.selected'),
  unselected: t('label.unselected')
})

export const isWebAuthBroker = () => {
  return navigator.userAgent.includes('MSAuthHost/1.0')
}

export const isPathnameOutsideSession = (pathname) => PAGES_WITHOUT_SESSION.includes(pathname)

export const shouldReduceUsernameLabel = (locale) => {
  return [
    'el_GR',
    'ru_RU',
    'es_AR',
    'es_BO',
    'es_CL',
    'es_CO',
    'es_CR',
    'es_DO',
    'es_EC',
    'es_ES',
    'es_GT',
    'es_HN',
    'es_LX',
    'es_MX',
    'es_NI',
    'es_PA',
    'es_PE',
    'es_PR',
    'es_PY',
    'es_SV',
    'es_UY',
    'es_VE'
  ].includes(locale)
}

export const shouldReduceMobileLabel = (locale) => {
  const reduceTo13px = ['cs_CZ', 'fi_FI', 'hr_HR', 'lv_LV', 'ro_RO', 'sk_SK', 'sl_SI', 'tr_TR']

  const reduceTo12px = ['lt_LT']

  const reduceTo11px = ['fr_BE', 'fr_CA', 'fr_CH', 'fr_FR', 'fr_LU', 'pl_PL', 'ru_RU']

  if (reduceTo13px.includes(locale)) return '13px'
  if (reduceTo12px.includes(locale)) return '12px'
  if (reduceTo11px.includes(locale)) return '11px'
}

export const filterUsername = (name) => (name ? name.replace(/[<>"\t]/g, '') : '')

export const checkValidCountry = (country) => /^[A-Z]{2}$/.test(country)

export const handleCheckboxOnKeyDown = () => {}

export const couldEndpointThrowTooManyRequestError = (endpoint) => {
  return [
    BFF_ENDPOINTS.SESSION_PHONE_SEND_CODE,
    BFF_ENDPOINTS.RECOVERY_USERNAME,
    BFF_ENDPOINTS.RECOVERY_PHONE_PASSWORD,
    BFF_ENDPOINTS.RECOVERY_PASSWORD,
    BFF_ENDPOINTS.SESSION_FEDERATED_EMAIL_CHANGE_PRIMARY,
    BFF_ENDPOINTS.SESSION_EMAIL_CHANGE_PRIMARY,
    BFF_ENDPOINTS.SESSION_FEDERATED_PHONE_CHANGE_PRIMARY,
    BFF_ENDPOINTS.SESSION_PHONE_CHANGE_PRIMARY,
    BFF_ENDPOINTS.SESSION_MFA_EMAIL_SEND_CODE,
    BFF_ENDPOINTS.SESSION_MFA_PHONE_SEND_CODE
  ].includes(endpoint)
}

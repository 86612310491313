import config from '../config.js'
import { checkLocalStorage } from '../util.js'
import { getIEClient } from '../util'

const isIE = getIEClient()
const ldEnabled = (config.featureFlags.launchdarkly || false) && !isIE

let clientId
let sessionId
let isInitializationSuccessful = true
let ldClient

export async function loadLaunchDarklyScript() {
  if (!ldEnabled) return

  return new Promise((resolve, reject) => {
    const script = document.createElement('script')
    script.addEventListener('load', resolve)
    script.addEventListener('error', reject)
    script.src = config.launchdarkly.url
    script.async = true
    document.head.appendChild(script)
  })
}

export function initializeClientId(infoClient, infoSession) {
  const getClientIdFromStorage = localStorage.getItem('clientId')
  const getSessionIdFromStorage = localStorage.getItem('sessionId')

  if (checkLocalStorage() && getClientIdFromStorage && getSessionIdFromStorage) {
    clientId = getClientIdFromStorage
    sessionId = getSessionIdFromStorage
  } else {
    clientId = infoClient
    sessionId = infoSession
    checkLocalStorage() && localStorage.setItem('clientId', clientId)
    checkLocalStorage() && localStorage.setItem('sessionId', sessionId)
  }
}

export async function initializeLDClient() {
  if (!ldEnabled) return

  const context = {
    kind: 'multi',
    user: {
      key: sessionId,
      anonymous: true
    },
    client: {
      key: clientId,
      anonymous: false
    }
  }

  ldClient = window.LDClient.initialize(config.launchdarkly.clientId, context)

  try {
    await ldClient.waitUntilReady()
  } catch (error) {
    isInitializationSuccessful = false
    console.error('Error during LaunchDarkly initialization: ', error)
  }
}

export async function getFeatureFlag(featureName) {
  if (!ldEnabled || !isInitializationSuccessful) {
    return config.featureFlags[featureName]
  }
  return ldClient?.variation(featureName)
}

const interfaceObj = {
  loadLaunchDarklyScript,
  initializeClientId,
  initializeLDClient,
  getFeatureFlag
}

export default interfaceObj

import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation, Trans } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { useLocation } from 'react-router-dom'
import Button from '@veneer/core/dist/esm/scripts/button'
import { ARKOSE_SELECTORS, PARAM_HIDE_CREATE, PAGE_SIGN_UP, USERNAME_TYPES } from '../../constants'
import { UILink, UIUsername, OTPSignInComponent } from '../../component'
import { validateMobileNumber, validateUsername, filterUsername, isValidEmail } from '../../util'
import { State } from '../../common/types'
import udlEvents from '../../common/udlEvents'

interface LocationState {
  isProhibitedEmail: boolean
}

const UsernameForm = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const location = useLocation()
  const { user, session, loading } = useSelector((state: State) => state)
  const [isLoading, setLoading] = useState<boolean>(false)
  const [isOtpLoading, setIsOtpLoading] = useState<boolean>(false)
  const [notMatchEmailError, setNotMatchEmailError] = useState<any>('')
  const [notMatchMobileError, setNotMatchMobileError] = useState<any>('')
  const isOTPSignInWithEmail = session.otpSignInEmail && user.usernameType === USERNAME_TYPES.USERNAME_EMAIL
  const isOTPSignInWithSMS = session.otpSignInSMS && user.usernameType === USERNAME_TYPES.USERNAME_MOBILE
  const isOTPSignInWithEmailAndSMS = isOTPSignInWithEmail && isOTPSignInWithSMS

  const shouldRenderOtpComponent = isOTPSignInWithEmail || isOTPSignInWithSMS || isOTPSignInWithEmailAndSMS
  const defaultValues = { username: user.username }
  const {
    handleSubmit,
    formState: { errors },
    setError,
    control,
    clearErrors
  } = useForm({ mode: 'onBlur', defaultValues })

  const { isProhibitedEmail } = (location.state as LocationState) || {}

  const submitSelector =  shouldRenderOtpComponent ? ARKOSE_SELECTORS.SIGN_IN.USE_PASSWORD_OTP :  ARKOSE_SELECTORS.SIGN_IN.USERNAME

  const email = {
    clearErrors: clearErrors,
    id: 'username',
    ariaLabel: 'username',
    label: t('label.username_or_email'),
    error: !!errors.username,
    helperText:
      errors.username && (errors.username.type === 'notMatch' ? notMatchEmailError : t(errors.username.message)),
    autoCapitalize: 'none',
    autoComplete: 'off',
    autoFocus: true,
    control,
    filter: filterUsername,
    rules: {
      required: 'form.err_username_email_empty',
      validate: (value) => {
        // check if OneHP provider and Native Login is available in client
        const allowSignInOneHPNative = session.supportedOneHP && session.client.supportHPIDNativeLogin
        if (allowSignInOneHPNative) return
        return validateUsername(value)
      }
    }
  }

  const mobile = {
    clearErrors: clearErrors,
    id: 'username',
    ariaLabel: 'username',
    required: false,
    error:
      errors.username && (errors.username.type === 'notMatch' ? notMatchMobileError : t(errors.username.message)),
    control,
    rules: {
      required: 'form.err_mobile_empty',
      validate: (value) => validateMobileNumber(value)
    }
  }

  useEffect(() => {
    if (user.username && user.username === user.loginHint) {
      const submitElement = document.getElementById(`${submitSelector}`)
      if (submitElement) {
        submitElement.focus()
      }
    }
    if (isProhibitedEmail) {
      setError('username', { message: 'form.err_invalid_hp_email' })
    }
  }, [isProhibitedEmail, setError, submitSelector, user.loginHint, user.username])

  useEffect(() => {
    setNotMatchEmailError(
      user[PARAM_HIDE_CREATE] ? (
        t('form.err_invalid_account_hide_create')
      ) : (
        <Trans i18nKey="form.err_account_not_found">
          HP account not found, click
          <UILink to={PAGE_SIGN_UP} error>
            here
          </UILink>
          to create an account.
        </Trans>
      )
    )
  }, [t, user])

  useEffect(() => {
    setNotMatchMobileError(
      user[PARAM_HIDE_CREATE] ? (
        t('form.err_invalid_account_hide_create')
      ) : (
        <Trans i18nKey="form.err_account_not_found_mobile">
          HP account not found. Please try signing in with your username or email address, or
          <UILink to={PAGE_SIGN_UP} error>
            create an account.
          </UILink>
        </Trans>
      )
    )
  }, [t, user])

  useEffect(() => {
    const udlErrorsEvent = () => {
      if (errors.username && errors.username.type === 'required') {
        if (user.usernameType === USERNAME_TYPES.USERNAME_EMAIL) {
          dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT69'))
        } else {
          dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT70'))
        }
      }

      if (errors.username && errors.username.type === 'notMatch') {
        if (user.usernameType === USERNAME_TYPES.USERNAME_EMAIL) {
          dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT71'))
        } else {
          dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT72'))
        }
      }
    }

    udlErrorsEvent()
  }, [errors.username, dispatch.udl, user.usernameType])

  const otpRedirect = async ({ username }) => {
    setIsOtpLoading(true)

    const isEmailValid = isValidEmail(username, true)
    const shouldCheckEmailForOtp = user.usernameType === USERNAME_TYPES.USERNAME_EMAIL && !isEmailValid.result

    if (shouldCheckEmailForOtp) {
      setIsOtpLoading(false)
      return setError('username', {
        message: isEmailValid.isHpEmail && isEmailValid.isEmail ? 'form.err_invalid_hp_email' : 'label.input_email'
      })
    }

    const trimmedValue = username.trim()
    const options = { setIsOtpLoading, setError, username: trimmedValue }

    await (dispatch as any).user.otpLoginSendCode({
      options: { ...options }
    })
  }

  const handleCheckUsername = async ({ username }) => {
    setLoading(true)
    const trimmedValue = username.trim()
    const options = { setLoading, setError, username: trimmedValue }
    const arkoseParams = {
      options,
      submitSelector,
      callback: (dispatch as any).user.usernameFormCheckUsername,
      onHide: () => setLoading(false),
      formErrors: errors
    }

    if (user.defaultUsernameType === USERNAME_TYPES.USERNAME_EMAIL) {
      if (user.usernameType === USERNAME_TYPES.USERNAME_EMAIL) {
        dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT31'))
      } else {
        dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT32'))
      }
    } else {
      if (user.usernameType === USERNAME_TYPES.USERNAME_MOBILE) {
        dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT33'))
      } else {
        dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT34'))
      }
    }

    await (dispatch as any).user.usernameFormCheckUsername({
      options: { ...options, arkoseParams }
    })
  }

  const triggerUdlEvent = () => {
    dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT64'))
  }

  return (
    <form onSubmit={handleSubmit(handleCheckUsername)}>
      <UIUsername cProps={[email, mobile]} />
      {shouldRenderOtpComponent ? (
        <OTPSignInComponent
          isOtpLoading={isOtpLoading}
          isPasswordLoading={isLoading}
          handleRedirectOtp={handleSubmit(otpRedirect)}
        />
      ) : (
        <div>
          <Button
            id={submitSelector}
            name="next"
            type="submit"
            loading={isLoading || loading.effects.user.loginWithHint}
            onClick={() => triggerUdlEvent()}
            expanded
          >
            {t('button_capitalize.next')}
          </Button>
        </div>
      )}
    </form>
  )
}

export default UsernameForm

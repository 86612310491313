import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Parse from 'libphonenumber-js/max'
import Button from '@veneer/core/dist/esm/scripts/button/button'
import IconChevronLeft from '@veneer/core/dist/esm/scripts/icons/icon_chevron_left'
import UISocialIcon from '../../component/UISocialIcon/UISocialIcon'
import DefaultHeader from '../../component/DefaultHeader'
import udlEvents from '../../common/udlEvents'
import { PAGE_USERNAME, PARAM_TARGET_PASSWORD } from '../../constants'
import { MainLayout } from '../../component'
import { State } from '../../common/types'

import * as S from './styles'

const RedirectIDP = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const user = useSelector((state: State) => state.user)
  const [username, setUsername] = useState(user.username)
  const identity = user.identities[0]
  const data = { target: PARAM_TARGET_PASSWORD }

  useEffect(() => {
    const parsedUsername = Parse(user.username)
    if (parsedUsername?.isValid()) setUsername(parsedUsername.formatInternational())
  }, [user.username])

  if (!identity) {
    navigate(PAGE_USERNAME, { replace: true })
    return null
  }

  const backButton = () => {
    dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT60'))
    dispatch.user.handleBackLink(data)
  }

  return (
    <MainLayout>
      <DefaultHeader title={t('button_capitalize.sign_in')} />
      <S.UsernameContainer>
        <S.BackLinkContainer>
          <Button
            id="back-link"
            small={true}
            appearance="ghost"
            aria-label="back-link"
            onClick={backButton}
            leadingIcon={<IconChevronLeft color="colorHpBlue6" display="inline-block" size={24} />}
          />
        </S.BackLinkContainer>
        <S.UsernameLabel>{username}</S.UsernameLabel>
      </S.UsernameContainer>
      <S.Description>{t('label.redirect_to_desc')}</S.Description>
      <S.Identities>
        <UISocialIcon provider={identity} button />
      </S.Identities>
    </MainLayout>
  )
}

export default RedirectIDP

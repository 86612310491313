import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
  LINK_SELECTORS,
  USERNAME_TYPES,
  PAGE_USERNAME,
  PAGE_SIGN_UP,
  PAGE_EMAIL_OR_PHONE_ASSOCIATED
} from '../../constants'
import UIMobileNumber from '../Controlled/UIMobileNumber'
import UITextBox from '../Controlled/UITextBox/UITextBox'
import UILink from '../UILink'
import udlEvents from '../../common/udlEvents'
import { State } from '../../common/types'
import { shouldReduceUsernameLabel } from '../../util'
import useDirection from '../../customHooks/useDirection'

import * as S from './styles'

const UIUsername = (props) => {
  const { cProps, allowSwitch = true, disableSwitch = false, marginBottom = 0, setHideTextDelay } = props

  const { t } = useTranslation()
  const location = useLocation()
  const { usernameType, locale } = useSelector((state: State) => state.user)
  const dispatch = useDispatch()
  const switchSelector = LINK_SELECTORS.SWITCH_USERNAME
  const [reducePlaceholder, setReducePlaceholder] = useState(shouldReduceUsernameLabel(locale))
  const { isRTL } = useDirection()

  const email = <UITextBox {...cProps[USERNAME_TYPES.USERNAME_EMAIL]} />
  const mobile = <UIMobileNumber {...cProps[USERNAME_TYPES.USERNAME_MOBILE]} />

  const triggerMobilelUdlEvent = () => {
    if (location.pathname === PAGE_USERNAME) return dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT62'))
    if (location.pathname === PAGE_SIGN_UP) return dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT78'))
    if (location.pathname === PAGE_EMAIL_OR_PHONE_ASSOCIATED)
      return dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT96'))
  }

  const triggerEmailUdlEvent = () => {
    if (location.pathname === PAGE_USERNAME) return dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT63'))
    if (location.pathname === PAGE_SIGN_UP) return dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT79'))
    if (location.pathname === PAGE_EMAIL_OR_PHONE_ASSOCIATED)
      return dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT95'))
  }

  const handleClick = () => {
    if (usernameType === USERNAME_TYPES.USERNAME_EMAIL) {
      dispatch.user.update({ usernameType: USERNAME_TYPES.USERNAME_MOBILE })
      triggerMobilelUdlEvent()
    } else {
      dispatch.user.update({ usernameType: USERNAME_TYPES.USERNAME_EMAIL })
      triggerEmailUdlEvent()
    }
  }

  useEffect(() => {
    setReducePlaceholder(shouldReduceUsernameLabel(locale))
  }, [locale])

  return (
    <S.UsernameWrapper reduceLabel={reducePlaceholder} marginBottom={isRTL ? 24 : marginBottom}>
      {usernameType === USERNAME_TYPES.USERNAME_EMAIL ? email : mobile}
      {allowSwitch && (
        <S.SwitchWrapper dir="ltr" preventLineBreak={locale === 'ru_RU'}>
          <UILink
            id={switchSelector}
            handleTimerChange={setHideTextDelay}
            onClick={handleClick}
            disable={disableSwitch}
          >
            {usernameType === USERNAME_TYPES.USERNAME_EMAIL ? t('link.mobile_switch') : t('link.email_switch')}
          </UILink>
        </S.SwitchWrapper>
      )}
    </S.UsernameWrapper>
  )
}

export default UIUsername
